import store from '@/state/store';
import { param } from 'jquery';

import { createRouter, createWebHistory } from "vue-router";
import { getCurrentUser } from 'vuefire';

const routes = [
  {
    path: "/",
    name: "Landingpage",
    meta: {
      title: "Dashboard",
    },
    component: () => import("../views/landingpage/index.vue"),
  },
  {
    path: "/workouts",
    name: "WODHERO Workouts",
    component: () => import("../views/landingpage/workouts.vue"),
    meta: {
      title: "Workouts",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to logged in version of all workouts
          next({ name: "/workouts/all" });
        } else {
          // Continue to the public workouts page
          next();
        }
      },
    },
  },
  {
    path: "/exercises",
    name: "WODHERO exercises",
    component: () => import("../views/landingpage/exercises.vue"),
    meta: {
      title: "Workouts",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to logged in version of all exercises
          next({ name: "/exercises/all" });
        } else {
          // Continue to the public exercise page
          next();
        }
      },
    },
  },
  {
    path: "/gyms",
    name: "WODHERO gyms",
    component: () => import("../views/landingpage/gyms.vue"),
    meta: {
      title: "Gyms",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to logged in version of all gyms
          next({ name: "/gyms/all" });
        } else {
          // Continue to the public gyms page
          next();
        }
      },
    },
  },
  {
    path: "/admin/musclegroups",
    name: "Muscle groups",
    meta: {
      title: "Muscle groups",
      isProtected: true,
    },
    component: () => import("../views/admin/musclegroups.vue"),

  },
  {
    path: "/admin/exercises",
    name: "Exercises",
    meta: {
      title: "Exercises",
      isProtected: true,
    },
    component: () => import("../views/admin/exercises.vue"),

  },
  {
    path: "/admin/gyms",
    name: "Wodhero Gyms",
    meta: {
      title: "All Wodhero Gyms",
      isProtected: true,
    },
    component: () => import("../views/admin/gyms.vue"),

  },
  {
    path: "/exercise/add",
    name: "Add exercise",
    meta: {
      title: "Add exercise",
      isProtected: true,
    },
    component: () => import("../views/exercise/add.vue"),

  },
  {
    path: "/admin/scoretypes",
    name: "WOD types",
    meta: {
      title: "WOD types",
      isProtected: true,
    },
    component: () => import("../views/admin/scoretypes.vue"),

  },
  {
    path: "/admin/wodcategories",
    name: "WOD categories",
    meta: {
      title: "WOD Categories",
      isProtected: true,
    },
    component: () => import("../views/admin/wodcategories.vue"),

  },
  {
    path: "/admin/workouts",
    name: "Workouts",
    meta: {
      title: "Workouts",
      isProtected: true,
    },
    component: () => import("../views/admin/workouts.vue"),

  },
  {
    path: "/admin/premium",
    name: "WODHERO PREMIUM",
    meta: {
      title: "Overview of Wodhero Premium users",
      isProtected: true,
    },
    component: () => import("../views/admin/premium.vue"),

  },
  {
    path: "/workout/add",
    name: "Add workout",
    meta: {
      title: "Add workout",
      isProtected: true,
    },
    component: () => import("../views/workout/add.vue"),

  },
  {
    path: "/workout/log",
    name: "Log workout",
    meta: {
      title: "Log a workout",
      isProtected: true,
    },
    component: () => import("../views/workout/log.vue"),

  },
  {
    //Add displayName and email to the path
    path: "/signin",
    name: "signin",
    component: () => import("../views/account/signin.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/account/signup.vue"),
    meta: {
      title: "Register",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/home",
    name: "app",
    meta: {
      title: "Wodhero",
      isProtected: true,
    },
    component: () => import("../views/dashboard/index.vue"),

  },
  {
    path: "/workout/all",
    name: "All workouts",
    meta: {
      title: "All workouts",
      isProtected: true,
    },
    component: () => import("../views/workout/all.vue"),

  },
  {
    path: "/workout/:id",
    name: "Workout",
    meta: {
      title: "Workout",
      isProtected: true,
    },
    component: () => import("../views/workout/show/index.vue"),
  },
  {
    path: "/workout/public/:id",
    name: "Workout public",
    meta: {
      title: "Workout",
    },
    component: () => import("../views/landingpage/showWorkout.vue"),
  },
  {
    path: "/exercise/all",
    name: "All exercises",
    meta: {
      title: "All exercises",
      isProtected: true,
    },
    component: () => import("../views/exercise/all.vue"),

  },
  {
    path: "/exercise/:id",
    name: "Exercise",
    meta: {
      title: "Exercise",
      isProtected: true,
    },
    component: () => import("../views/exercise/show/index.vue"),

  },
  {
    path: "/exercise/public/:id",
    name: "Exercise public",
    meta: {
      title: "Exercise",
    },
    component: () => import("../views/landingpage/showExercise.vue"),

  },
  {
    path: "/gym/create",
    name: "Create gym",
    meta: {
      title: "Create new gym",
      isProtected: true,
    },
    component: () => import("../views/gym/create.vue"),

  },
  {
    path: "/gym/all",
    name: "All gyms",
    meta: {
      title: "All gyms",
      isProtected: true,
    },
    component: () => import("../views/gym/all.vue"),

  },
  {
    path: "/gym/:id/memberships",
    name: "Gym memberships",
    meta: {
      title: "Gym memberships overview",
      isProtected: true,
    },
    component: () => import("../views/gym/memberships.vue"),

  },
  {
    path: "/gym/public/:id/memberships",
    name: "Gym memberships public",
    meta: {
      title: "Gym memberships overview",
    },
    component: () => import("../views/landingpage/showGym.vue"),

  },
  {
    path: "/gym/:id/admin",
    name: "Gym administration",
    meta: {
      title: "Gym Adminstration",
      isProtected: true,
    },
    component: () => import("../views/gym/admin/products.vue"),

  },
  {
    path: "/gym/:id",
    name: "Gym",
    meta: {
      title: "Gym",
      isProtected: true,
    },
    component: () => import("../views/gym/show.vue"),

  },
  {
    path: "/gym/:id/admin/products",
    name: "Gym product administration",
    meta: {
      title: "Gym product Adminstration",
      isProtected: true,
    },
    component: () => import("../views/gym/admin/products.vue"),

  },
  {
    path: "/gym/:id/admin/settings/stripe",
    name: "Gym stripe administration",
    meta: {
      title: "Gym stripe Adminstration",
      isProtected: true,
    },
    component: () => import("../views/gym/admin/settings/stripe.vue"),

  },
  {
    path: "/gym/:id/admin/pricing",
    name: "Gym pricing administration",
    meta: {
      title: "Gym pricing Adminstration",
      isProtected: true,
    },
    component: () => import("../views/gym/admin/pricing.vue"),

  },
  {
    path: "/gym/:id/admin/product/add",
    name: "Add new product",
    meta: {
      title: "Add new product",
      isProtected: true,
    },
    component: () => import("../views/gym/admin/addProduct.vue"),

  },
  {
    path: "/gym/:id/admin/subscriptions",
    name: "Gym subscriptions overview",
    meta: {
      title: "Gym subscriptions",
      isProtected: true,
    },
    component: () => import("../views/gym/admin/subscriptions.vue"),

  },
  {
    path: "/gym/:id/admin/subscriptions/:subscriptionid",
    name: "Gym subscription details",
    meta: {
      title: "Gym subscription",
      isProtected: true,
    },
    component: () => import("../views/gym/admin/showSubscription.vue"),

  },
  {
    path: "/gym/:id/admin/memberships",
    name: "Gym members overview",
    meta: {
      title: "Gym members",
      isProtected: true,
    },
    component: () => import("../views/gym/admin/members.vue"),

  },
  {
    path: "/gym/:id/admin/memberships/:memberid",
    name: "Gym member details",
    meta: {
      title: "Gym member",
      isProtected: true,
    },
    component: () => import("../views/gym/admin/showMember.vue"),

  },
  {
    path: "/gym/:id/admin/invoices",
    name: "Gym invoices overview",
    meta: {
      title: "Gym invoices",
      isProtected: true,
    },
    component: () => import("../views/gym/admin/invoices.vue"),

  },
  {
    path: "/gym/:id/admin/invoices/:invoiceid",
    name: "Gym invoice details",
    meta: {
      title: "Gym invoice",
      isProtected: true,
    },
    component: () => import("../views/gym/admin/showInvoice.vue"),

  },
  {
    path: "/gym/:id/admin/member/:memberid/invoices/create",
    name: "Create manual invoice",
    meta: {
      title: "Gym Create manuel invoice for gym member",
      isProtected: true,
    },
    component: () => import("../views/gym/admin/createInvoice.vue"),

  },
  {
    path: "/gym/:id/admin/classes",
    name: "Gym Classes & events overview",
    meta: {
      title: "Gym Classes & events",
      isProtected: true,
    },
    component: () => import("../views/gym/admin/classes.vue"),

  },
  {
    path: "/gym/:id/admin/class/:classid",
    name: "Gym class details",
    meta: {
      title: "Edit gym class or event details",
      isProtected: true,
    },
    component: () => import("../views/gym/admin/editClass.vue"),

  },
  {
    path: "/gym/:id/class/:classid/instance/:instanceid", //Show a specific instance of a class
    name: "Class overview",
    meta: {
      title: "Gym class details",
      isProtected: true,
    },
    component: () => import("../views/class/show.vue"),

  },
  {
    path: "/gym/:id/class/:classid/instance/:instanceid/program", //Program an instance of a class
    name: "Class programming",
    meta: {
      title: "Program a class",
      isProtected: true,
    },
    component: () => import("../views/class/program.vue"),

  },
  {
    path: "/gym/:id/admin/class/add",
    name: "Add new class or event",
    meta: {
      title: "Add new class or event",
      isProtected: true,
    },
    component: () => import("../views/gym/admin/addClass.vue"),

  },
  {
    path: "/gym/:id/admin/coupons",
    name: "Gym Coupons & credits overview",
    meta: {
      title: "Gym discounts",
      isProtected: true,
    },
    component: () => import("../views/gym/admin/coupons.vue"),

  },
  {
    path: "/gym/:id/admin/coupons/:stripeCouponID",
    name: "Gym Coupon overview",
    meta: {
      title: "Gym Coupon",
      isProtected: true,
    },
    component: () => import("../views/gym/admin/showCoupon.vue"),

  },
    {
    path: "/gym/:id/admin/coupons/:stripeCouponID",
    name: "Gym Coupon overview",
    meta: {
      title: "Gym Coupon",
      isProtected: true,
    },
    component: () => import("../views/gym/admin/showCoupon.vue"),

  },
  {
    path: "/gym/:id/admin/statistics",
    name: "Gym statistics overview",
    meta: {
      title: "Gym stats",
      isProtected: true,
    },
    component: () => import("../views/gym/admin/statistics.vue"),

  },
  {
    path: "/gym/buy/product/:id",
    name: "Buy product",
    meta: {
      title: "Buy a new product",
      isProtected: true,
    },
    component: () => import("../views/gym/buy.vue"),

  },
  {
    path: "/profile",
    name: "Profile",
    meta: {
      title: "Profile",
      isProtected: true,
    },
    component: () => import("../views/account/profile.vue"),

  },
  {
    path: "/profile/settings",
    name: "Profile settings",
    meta: {
      title: "Profile settings",
      isProtected: true,
    },
    component: () => import("../views/account/settings.vue"),

  },
  {
    path: "/profile/calculator",
    name: "Wodhero Calculator",
    meta: {
      title: "Wodhero calculator",
      isProtected: true,
    },
    component: () => import("../views/account/calculator.vue"),

  },
  {
    path: "/profile/:id",
    name: "User profile",
    meta: {
      title: "user profile page",
      isProtected: true,
    },
    component: () => import("../views/account/publicprofile.vue"),

  },
  {
    path: "/profile/invoices",
    name: "User invoices",
    meta: {
      title: "User invoices overview",
      isProtected: true,
    },
    component: () => import(/* webpackChunkName: "invoices" */ '../views/account/Invoices.vue'),

  },
  {
    path: "/profile/subscriptions",
    name: "User subscriptions",
    meta: {
      title: "User subscriptions overview",
      isProtected: true,
    },
    component: () => import("../views/account/subscriptions.vue"),

  },
  {
    path: "/profile/invoices/:id",
    name: "User invoice details",
    meta: {
      title: "User invoice details overview",
      isProtected: true,
    },
    component: () => import("../views/account/showInvoice.vue"),

  },
  {
    path: "/profile/premium/subscribe",
    name: "Wodhero Premium",
    meta: {
      title: "Subscripe to Wodhero Premium",
      isProtected: true,
    },
    component: () => import("../views/account/buypremium.vue"),
  },
  {
    path: "/profile/:id/logbook",
    name: "Logbook",
    meta: {
      title: "Your wodhero logbook",
      isProtected: true,
    },
    component: () => import("../views/account/logbook.vue"),
  },
  {
    path: "/500",
    name: "500",
    meta: {
      title: "500",
    },
    component: () => import("../views/500.vue"),
  },
  //Create af 404 route that catches all routes that are not defined
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    meta: {
      title: "404",
    },
    component: () => import("../views/404.vue"),
  },
];

const router = createRouter({
  history: createWebHistory('/'),
  routes,
});

async function authGuard(to) {
  // Ensures the user is initialized 
  const user = await getCurrentUser()
  if(to.meta.isProtected && !user) {
    // Redirect to a login page
    return '/';
  }
}

router.beforeEach(authGuard);

export default router;