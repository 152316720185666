import { initializeApp } from 'firebase/app'
// ... other firebase imports

export const firebaseApp = initializeApp({
    apiKey: "AIzaSyBDrbCMJg7lWJemViODmp0Ka9HcLfycScQ",
    authDomain: "wodhero-bb856.firebaseapp.com",
    projectId: "wodhero-bb856",
    storageBucket: "wodhero-bb856.appspot.com",
    messagingSenderId: "590508999149",
    appId: "1:590508999149:web:cdc0b7c4d22a521ff3e7e4",
    measurementId: "G-NFR5EBQ6SN"
})
