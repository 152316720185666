import { createApp } from 'vue';
import { VueFire, VueFireAuth } from 'vuefire';
import App from './App.vue';
import { firebaseApp } from './helpers/authservice/firebaseconfig';
import router from "./router";
import AOS from 'aos';
import 'aos/dist/aos.css';
import i18n from './i18n';
import store from "./state/store";
import BootstrapVue3 from 'bootstrap-vue-3';
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import Maska from 'maska';
import 'vue-rate/dist/vue-rate.css'
import VueFeather from 'vue-feather';
import Particles from "particles.vue3";
import Cleave from 'vue-cleave-component';

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

import '@/assets/scss/config/interactive/app.scss';
import '@vueform/slider/themes/default.css';
AOS.init({
    easing: 'ease-out-back',
    duration: 1000
});

createApp(App)
    .use(store)
    .use(router)
    .use(VueApexCharts)
    .use(BootstrapVue3)
    .component(VueFeather.type, VueFeather)
    .use(Maska)
    .use(Cleave)
    .use(Particles)
    .use(i18n)
    .use(Toast, {
      transition: "my-custom-fade",
      maxToasts: 20,
      newestOnTop: true
    })
    .use(VueFire, {
      // imported above but could also just be created here
      firebaseApp,
      modules: [
        // we will see other modules later on
        VueFireAuth(),
      ],
    })
    .use(vClickOutside).mount('#app');